(function() {
  'use strict';

  var $ = jQuery.noConflict();

  app.MapWidget = {
    init: function() {
      var hhLatLng = new google.maps.LatLng(48.112320,-1.677033);
      var mapOptions = {
        center: hhLatLng,
        zoom: 15,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
        draggable: false,
        disableDefaultUI: false,
        disableDoubleClickZoom: true,
        panControl: false,
        mapTypeControl: false,
        styles: [
          {
            "featureType": "administrative",
            "stylers": [
              { "visibility": "off" }
            ]
          },{
            "featureType": "poi",
            "stylers": [
              { "visibility": "off" }
            ]
          },{
            "featureType": "landscape.man_made",
            "stylers": [
              { "color": "#efe1cf" }
            ]
          },{
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              { "visibility": "off" }
            ]
          },{
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
              { "color": "#d1bda4" }
            ]
          },{
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [
              { "color": "#fbf3e8" }
            ]
          },{
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
              { "color": "#d8b4cc" }
            ]
          },{
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              { "visibility": "off" }
            ]
          }
        ]
      };
  
      var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
  
      var marker = new google.maps.Marker({
        position: hhLatLng,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: '/assets/img/pinpoint.png'
      });
      
      google.maps.event.addListener(marker, 'click', function () {
        window.open('https://goo.gl/maps/jc9UwyabPJ82');
      });
      
      google.maps.event.addListener(map, 'bounds_changed', function() {
        map.setCenter(hhLatLng);
      });
    }
  };

  app.MapWidget.init();
})();
